export type NewsEntry = {
  title: string
  startDate: string // 'YYYY-MM-DD
  endDate?: string // 'YYYY-MM-DD
  text: string
  displayAfterDate: boolean
  time?: string
  link?: string
}

export const newsEntries: Array<NewsEntry> = [

  {
    title: 'Photobastei',
    startDate: '2024-05-05',
    endDate: '2024-05-26',
    time: '',
    text: 'Group Exhibition featuring works by Aysegul Kaya, Ina Wecken, Maja Malmcrona, Nicolas Coia, Nicole Salnikov and Vincent Weber.',
    displayAfterDate: true,
    link: '',
  },
  {
    title: 'Try the Gallery',
    startDate: '2024-02-22',
    endDate: '2024-05-05',
    time: '17:00 - 21:00',
    text: 'Solo Exhibiton with works by Vincent Weber. The opening takes place at the 22.02.24 at Bellerivestrasse 147 in Zurich.',
    displayAfterDate: true,
    link: '',
  },

  {
    title: 'Regionale Wallis 2023',
    startDate: '2023-11-11',
    endDate: '2023-12-03',
    time: '11:00 - 18:00',
    text: 'An annual exhibition featuring works by artists from Valais, selected by a jury. The Gallery zur Matze showcases pieces by the following artists: Manuela Brügger, Pinaki, Cornelia Egli, Elisabeth Fux Mattig, Bernd Kniel, Coline Ladetto, Aurèle Oggier, Eva Maria Pfaffen, Rittiner & Gomez, Carlo Schmidt, Philip Spiegel, Valeria Triulzi, Vincent Weber, Wilhelm Wenger, Nicolas Witschi, Jonas Wyssen, Isabelle Zeltner, and Liliane Zumkemi.',
    displayAfterDate: true,
    link: 'https://kunstvereinoberwallis.ch/?post_type=kvo_gallery&p=115&element=aktuell',
  },

  {
    title: 'Group Exhibition Zurich Atelier West',
    startDate: '2023-02-25',
    time: '12:00 - 18:00',
    text: 'The group exhibition at Atelier West will show the works of three Switzerland-based artists: Vincent Weber, Maja Malmcrona, and Aaron Pérez. This exhibition brings together ideas from three different approaches and will display drawings. paintings, and sculptures in a range of materials and scales. The venue will be open between 12:00 to 18:00 and beyond the art itself offer live music by Jacob Dhondt (15:00-16:00).',
    displayAfterDate: true,
    link: 'https://www.window-of-fame.ch/index.html',
  },
]
