export enum ContentType {
  Title = 'Title',
  AnchorTag = 'AnchorTag',
  Text = 'Text',
  Break = 'Break',
}

export type AboutType =
  | {
      type: ContentType.Title
      text: string
    }
  | {
      type: ContentType.Text
      text: string
    }
  | {
      type: ContentType.Break
    }
  | {
      type: ContentType.AnchorTag
      text: string
      mailTo?: string
      openLink?: string
    }

export const aboutText = [
  { type: ContentType.Title, text: 'Profile' },
  { type: ContentType.Text, text: '• Born 1994 in Zurich, Switzerland' },
  { type: ContentType.Text, text: '• Theater studies at HMT Rostock'},
  { type: ContentType.Text, text: '• Self-taught Painter'},

  { type: ContentType.Title, text: 'Statement' },
  { type: ContentType.Text, text: 'My works attempt to combine my strong interest in philosophical, religious, and scientific topics with my urge to be impulsive, spontaneous, playful, and thoughtless. By contrasting symbolic and religious imagery with naive and almost childlike forms of expression, I strive to address central human problems in a natural way.' },
  { type: ContentType.Text, text: 'However, not only the finished image but also the creative process can be understood in this Polarity. Through my theater experience, I have learned to approach my paintings impulsively, playfully, and spontaneously. I usually start abstractly without an idea or plan for the painting, to then transfer the meaning contained in the abstract image over several layers into a concrete motif. During the process, I link the image with my dreams or themes from religious and modern texts. Only when the image has taken on a concrete form do I begin to question what it could mean. This process helps me to go beyond my current understanding and comprehension with the image and prevents the image from becoming over-intellectualized. Each image discusses a fundamental theme on its own. Certain motifs I revisit in new images.'},
  { type: ContentType.Text, text: 'This process is similar to that of abstract painters like Gerhard Richter or Per Kirkeby, who sharpen the abstract image over several layers to get to the essence. However, I do not stop at the abstract image but make it concrete. Contrary to classical modernism, which abstracts from the object, I move from the abstract image to the representational and concrete motif.'},
  { type: ContentType.Text, text: 'I work mainly on wood, as it allows me to implement impulses directly and permanently in the form of woodcarving and pyrography and is flexible for combining different materials (e.g., oil and pastel chalk). I usually work on several images at the same time, as this keeps my view open and allows me to roam freely and without bias from image to image.'},
  
]

