import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { Home } from 'pages/Home'
import { Scaffold } from 'Scaffold'
import { About } from 'pages/About'
import { News } from 'pages/News'
import { Contact } from 'pages/Contact'

export const GlobalStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overscroll-behavior-x: none;
  }

  #root {
    margin: 0;
    padding: 0;
    overscroll-behavior-x: none;
  }

  body {
    margin: 0;
    padding:0;
    overscroll-behavior-x: none;
  }
  
  a {
    line-height: 1.35em;
    font-size: 14px;
    font-weight: 400;
  }

  h1, h2, h3, h4, p, a, input, label, textarea {
    font-family: 'Garamond', serif !important;
    margin: 0;
    text-decoration: none;
    color: black;
  }

  h1 {
    font-size: 25px;
  }

  p {
    line-height: 1.35em;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
  }

  * {
    box-sizing: border-box;
  }
`

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Scaffold>
          <Routes>
            <Route path={'/'} element={<Home />} />
            <Route path={'/about'} element={<About />} />
            <Route path={'/news'} element={<News />} />
            <Route path={'/contact'} element={<Contact />} />
          </Routes>
        </Scaffold>
      </BrowserRouter>
    </>
  )
}
