import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import { ArtPiece } from 'constants/vinceZone/galleries/earlyWorks'
import { useEffect, useRef } from 'react'
import { useIsMobile } from 'Scaffold'
import styled from 'styled-components'
import { ArtWork } from './ArtWork'
import { ImgDescription } from './ImgDescription'

export const ArtSlider = (props: {
  pieces: Array<ArtPiece>
  setFullScreenPiece: (v: number) => void
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const isMobile = useIsMobile()

  useEffect(() => {
    ref.current &&
      ref.current.scroll({
        left: 0,
        behavior: 'smooth',
      })
  }, [props.pieces])

  return (
    <ArtSliderWrapper ref={ref}>
      {props.pieces.map((piece, idx) => (
        <div
          key={idx}
          style={{ cursor: 'pointer' }}
          onClick={() => props.setFullScreenPiece(idx)}
        >
          <ArtWork key={idx} info={piece} />
          {isMobile && <ImgDescription piece={piece} />}
        </div>
      ))}
    </ArtSliderWrapper>
  )
}

const ArtSliderWrapper = styled.div`
  display: flex;
  flex: 4;
  position: relative;
  overflow: hidden;

  animation-name: appearSlowSlider;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  @keyframes appearSlowSlider {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${DESKTOP_STYLE} {
    align-items: center;
    overflow-x: scroll;
    margin: 24px 0px;
  }

  ${MOBILE_STYLE} {
    overflow-y: scroll;
    flex-direction: column;
  }
`
