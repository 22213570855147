import { NewsEntry } from 'constants/vinceZone/newsEntries'
import styled from 'styled-components'
import { FiExternalLink } from 'react-icons/fi'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'

export const NewsCard = (props: { news: NewsEntry }) => {
  const { news } = props
  const startDateArr = news.startDate.split('-')

  return (
    <Card>
      <Date>
        <p>{`${startDateArr[2]}.${startDateArr[1]}.${startDateArr[0]}`} </p>
        {news.endDate && (
          <>
            <p style={{ margin: '0px 4px' }}>-</p>
            <p>
              {`${news.endDate.split('-')[2]}.${news.endDate.split('-')[1]}.${
                news.endDate.split('-')[0]
              }`}
            </p>
          </>
        )}
      </Date>

      <Text>
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <p>{news.title.toUpperCase()}</p>
          {news.link && (
            <Link href={news.link} target="_blank">
              <FiExternalLink size={18} />
            </Link>
          )}
        </div>
        <p>{news.text}</p>
      </Text>
    </Card>
  )
}

const Card = styled.div`
  display: flex;
  flex: 0 0 80px;
  width: 640px;
  max-width: 100%;
  margin-bottom: 64px;

  ${DESKTOP_STYLE} {
    flex-direction: row;
  }

  ${MOBILE_STYLE} {
    flex-direction: column;
  }
`

const Date = styled.div`
  display: flex;
  padding: 2px;

  ${DESKTOP_STYLE} {
    flex: 0 0 180px;
  }

  ${MOBILE_STYLE} {
    flex: 0 0 40px;
  }
`
const Link = styled.a``

const Text = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`
