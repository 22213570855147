import { ArtPiece } from './earlyWorks'

export const Selected: Array<ArtPiece> = [
  
 
  {
    imgFullName: 'Man with white dove.jpeg',
    widthInCm: 40,
    heightInCm: 60,
    details: {
      name: 'Mann mit Taube',
      materialAndPaint: 'Oil, pastel chalk on wood',
      date: 'January 2023',
    },
  },
  {
    imgFullName: 'Mädchen_mit_Vogel.jpg',
    widthInCm: 60,
    heightInCm: 60,
    details: {
      name: 'Vogel an der Leine',
      materialAndPaint: 'Acrylics, pastel chalk on wood',
      date: 'March 2023',
    },
  },
  {
    imgFullName: 'Herrin-min.jpg',
    widthInCm: 60,
    heightInCm: 64,
    details: {
      name: 'Herrin',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'January 2024',
    },
  },
  {
    imgFullName: 'Am roten Meer-min.jpg',
    widthInCm: 60,
    heightInCm: 54,
    details: {
      name: 'Am roten Meer',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'November 2023',
    },
  },
  {
    imgFullName: 'puer-aeternus_min.jpg',
    widthInCm: 63,
    heightInCm: 104,
    details: {
      name: 'Puer Aeternus',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'September 2023',
    },
  },
  {
    imgFullName: 'Blume-min.jpg',
    widthInCm: 63,
    heightInCm: 134,
    details: {
      name: 'Blume',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'December 2023',
    },
  },
  {
    imgFullName: 'Am Ursprung-min.jpg',
    widthInCm: 45,
    heightInCm: 75,
    details: {
      name: 'Am Ursprung',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'December 2023',
    },
  },
  {
    imgFullName: 'Beleuchteet-min.jpg',
    widthInCm: 61,
    heightInCm: 76,
    details: {
      name: 'Beleuchtet',
      materialAndPaint: 'Oil on wood',
      date: 'December 2023',
    },
  },


  
  
  {
    imgFullName: 'BaloonShooterGreen.jpeg',
    widthInCm: 50,
    heightInCm: 60,
    details: {
      name: 'grüner Ballon',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'April 2022',
    },
  },
  {
    imgFullName: 'Kopf und Perle.jpg',
    widthInCm: 0,
    heightInCm: 0,
    details: {
      name: 'Kopf und perle',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'February 2023',
    },
  },
  
  
  {
    imgFullName: 'Sprungturm2.jpg',
    widthInCm: 40,
    heightInCm: 185,
    details: {
      name: 'Sprungturm Nr.2',
      materialAndPaint: 'charcoal, oil, pastel chalk on wood',
      date: 'December 2022',
    },
  },
  {
    imgFullName: 'Gieskanne.jpg',
    widthInCm: 100,
    heightInCm: 100,
    details: {
      name: 'Gärtner und Vogel',
      materialAndPaint: 'Acrylics, charcoal, pastel chalk on canvas',
      date: 'July 2022',
    },
  },



  
]
