import { ArtSlider } from 'components/ArtSlider'
import { FullScreenView } from 'components/FullScreenView'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import { balloonSeries } from 'constants/vinceZone/galleries/balloonSeries'
import { ArtPiece } from 'constants/vinceZone/galleries/earlyWorks'
import { onWood } from 'constants/vinceZone/galleries/onWood'
import { Digitalized } from 'constants/vinceZone/galleries/Digitalized'
import { storyPaintings } from 'constants/vinceZone/galleries/storyPaintings'
import { Spring_2022 } from 'constants/vinceZone/galleries/Spring_2022'
import { Autumn_2022 } from 'constants/vinceZone/galleries/Autumn_2022'
import { Spring_2023 } from 'constants/vinceZone/galleries/Spring_2023'
import { Autumn_2023 } from 'constants/vinceZone/galleries/Autumn_2023'
import { Selected } from 'constants/vinceZone/galleries/Selected'
import { Drawings } from 'constants/vinceZone/galleries/Drawings'
import { useState } from 'react'
import { useIsMobile } from 'Scaffold'
import styled from 'styled-components'

export const Home = () => {
  const isMobile = useIsMobile()

  const [fullScreenPiece, setFullScreenPiece] = useState<number>()

  const galleries: Array<{ name: string; pieces: Array<ArtPiece> }> = [
    { name: 'Selected', pieces: Selected },
    { name: 'Autumn 2023', pieces: Autumn_2023 },
    { name: 'Spring 2023', pieces: Spring_2023 },
    { name: 'Autumn 2022', pieces: Autumn_2022 },
    { name: 'Spring 2022', pieces: Spring_2022 },
    { name: 'Drawings', pieces: Drawings },
    { name: 'Digitalized', pieces: Digitalized },
  ]

  const [selectedGallery, setSelectedGallery] = useState(0)

  return (
    <Wrapper>
      {fullScreenPiece !== undefined && !isMobile && (
        <FullScreenView
          pieces={galleries[selectedGallery].pieces}
          fullScreenPiece={fullScreenPiece}
          setFullScreenPiece={setFullScreenPiece}
          close={() => setFullScreenPiece(undefined)}
        />
      )}
      <GallerySelector>
        {galleries.map((v, idx) => (
          <p
            style={{
              fontSize: 16,
              cursor: 'pointer',
              fontWeight: selectedGallery === idx ? 'bold' : 'normal',
            }}
            onClick={() => setSelectedGallery(idx)}
          >
            {v.name}
          </p>
        ))}
      </GallerySelector>
      <ArtSlider
        key={selectedGallery}
        pieces={galleries[selectedGallery].pieces}
        setFullScreenPiece={setFullScreenPiece}
      />
      {!isMobile && <Floor></Floor>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`

const Floor = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  background-color: #eeeeee;
`

const GallerySelector = styled.div`
  display: flex;
  justify-content: center;

  ${DESKTOP_STYLE} {
    gap: 24px;
    flex: 1;
    align-items: center;
  }

  ${MOBILE_STYLE} {
    flex-direction: column;
    align-items: flex-end;
    padding: 32px 24px;
    width: 100%;
    gap: 16px;
  }
`
