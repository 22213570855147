import { ArtPiece } from './earlyWorks'

export const Autumn_2022: Array<ArtPiece> = [
  
  {
    imgFullName: 'Gieskanne.jpg',
    widthInCm: 100,
    heightInCm: 100,
    details: {
      name: 'Gärtner und Vogel',
      materialAndPaint: 'Acrylics, charcoal, pastel chalk on canvas',
      date: 'July 2022',
    },
  },
  {
    imgFullName: 'Sprungturm2.jpg',
    widthInCm: 40,
    heightInCm: 185,
    details: {
      name: 'Sprungturm Nr.2',
      materialAndPaint: 'charcoal, oil, pastel chalk on wood',
      date: 'December 2022',
    },
  },
  {
    imgFullName: 'Taucher mit Fisch.jpg',
    widthInCm: 0,
    heightInCm: 0,
    details: {
      name: 'verkopft',
      materialAndPaint: 'Acrylics, pastel chalk on wood',
      date: 'January 2023',
    },
  },
  {
    imgFullName: 'exploding head yellow.jpg',
    widthInCm: 100,
    heightInCm: 120,
    details: {
      name: 'gelber Ballon',
      materialAndPaint: 'Acrylics, charcoal, pastel chalk on canvas',
      date: 'November 2022',
    },
  },
  {
    imgFullName: 'BaloonExplodingGreen.jpg',
    widthInCm: 100,
    heightInCm: 120,
    details: {
      name: 'grüner Ballon Nr.2',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'November 2022',
    },
  },
  {
    imgFullName: 'Kore.jpg',
    widthInCm: 50,
    heightInCm: 60,
    details: {
      name: 'Hals über Kopf',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'December 2022',
    },
  },

{
  imgFullName: 'abstract-baloon.jpg',
  widthInCm: 60,
  heightInCm: 80,
  details: {
    name: 'brauner Ballon',
    materialAndPaint: 'Acrylics, pastel chalk on cardboard',
    date: 'November 2022',
  },
}
  
]
