import styled from 'styled-components'

export const FullScreenImg = (props: { imgName: string }) => {
  return (
    <Image
      alt={props.imgName}
      loading={'lazy'}
      src={`gallery/${props.imgName}`}
    />
  )
}

const Image = styled.img`
  object-fit: contain;
  height: 60vh;
  max-width: 100%;
  margin-bottom: 24px;
`
