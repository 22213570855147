import { AboutType, ContentType } from 'constants/vinceZone/aboutText'
import { contactText } from 'constants/vinceZone/contactText'
import styled from 'styled-components'

export const ContactText = () => {
  const renderContent = (element: AboutType) => {
    switch (element.type) {
      case ContentType.Title:
        return <p>{element.text.toUpperCase()}</p>
      case ContentType.Text:
        return <p>{element.text}</p>
      case ContentType.AnchorTag:
        return (
          <a
            href={
              element.mailTo
                ? `mailto:${element.mailTo}`
                : `${element.openLink}`
            }
            target={'_blank'}
            rel={'noreferrer'}
          >
            {element.text}
          </a>
        )
      case ContentType.Break:
        return <Break />
    }
  }
  return (
    <TextContainer>
      {contactText.map((el, idx) => (
        <div
          style={{
            display: 'flex',
            padding: '8px 0px',
          }}
          key={idx}
        >
          {renderContent(el)}
        </div>
      ))}
    </TextContainer>
  )
}

const TextContainer = styled.div`
  display: flex;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  max-width: 720px;
`

const Break = styled.div``
