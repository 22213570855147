import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import { ArtPiece } from 'constants/vinceZone/galleries/earlyWorks'
import styled from 'styled-components'

export const ArtWork = (props: { info: ArtPiece }) => {
  return (
    <Wrapper>
      <Canvas
        style={{
          objectFit: 'contain',
        }}
        loading={'lazy'}
        src={`gallery/${props.info.imgFullName}`}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  transition: 1s;
  opacity: 0;
  position: relative;

  ${DESKTOP_STYLE} {
    flex-direction: column;
    height: 40vh;
    justify-content: center;
  }

  ${MOBILE_STYLE} {
    margin: 40px 0px;
    justify-content: center;
    align-items: center;
  }

  animation-name: appearSlow;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  @keyframes appearSlow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Canvas = styled.img`
  display: flex;
  z-index: 20;

  ${DESKTOP_STYLE} {
    margin: 32px 64px;
    height: 100%;
  }

  ${MOBILE_STYLE} {
    width: 100%;
  }
`
