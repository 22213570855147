import { AboutText } from 'components/AboutText'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import styled from 'styled-components'

export const About = () => {
  return (
    <Wrapper>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          maxWidth: 720,
          marginBottom: 42,
        }}
      >
        <img
          src="portrait.jpeg"
          alt="Portrait Vincent"
          style={{ width: 200 }}
        />
      </div>
      <AboutText />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  align-items: center;

  ${DESKTOP_STYLE} {
    padding: 64px;
  }

  ${MOBILE_STYLE} {
    padding: 32px 24px;
  }
`
