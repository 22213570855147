import { ArtPiece } from './earlyWorks'

export const Spring_2022: Array<ArtPiece> = [
  
  {
    imgFullName: 'BaloonShooterGreen.jpeg',
    widthInCm: 50,
    heightInCm: 60,
    details: {
      name: 'grüner Ballon',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'April 2022',
    },
  },
  
  {
    imgFullName: 'Wiedergeburt.jpeg',
    widthInCm: 70,
    heightInCm: 50,
    details: {
      name: 'schwebende Frau',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'January 2022',
    },
  },
  {
    imgFullName: 'Transformation.jpeg',
    widthInCm: 100,
    heightInCm: 70,
    details: {
      name: 'Veränderung',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'February 2022',
    },
  },
  
  {
    imgFullName: 'Sprungturm.jpeg',
    widthInCm: 30,
    heightInCm: 100,
    details: {
      name: 'Sprungturm',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'March 2022',
    },
  },
  {
    imgFullName: 'Man with Candle.jpeg',
    widthInCm: 36,
    heightInCm: 47,
    details: {
      name: 'Mann mit Kerze',
      materialAndPaint: 'Acrylics, pastel chalk on wood',
      date: 'April 2022',
    },
  },
  {
    imgFullName: 'PocaHontas.jpeg',
    widthInCm: 36,
    heightInCm: 47,
    details: {
      name: 'Frau mit orangen Haaren',
      materialAndPaint: 'Acrylics, pastel chalk on wood',
      date: 'April 2022',
    },
  },
  {
    imgFullName: 'weinende Frau.jpg',
    widthInCm: 50,
    heightInCm: 50,
    details: {
      name: 'Frau mit Tränen',
      materialAndPaint: 'Acrylics, pastel chalk on wood',
      date: 'April 2022',
    },
  },
  {
    imgFullName: 'TimeRabbitHeadache.jpeg',
    widthInCm: 58,
    heightInCm: 86,
    details: {
      name: 'kranker Mann mit Hase',
      materialAndPaint: 'Acrylics, pastel chalk on wood',
      date: 'April 2022',
    },
  },

  {
    imgFullName: 'Fall.jpeg',
    widthInCm: 60,
    heightInCm: 74,
    details: {
      name: 'fallender Mensch',
      materialAndPaint: 'Acrylics, charcoal on wood',
      date: 'April 2022',
    },
  },
  {
    imgFullName: 'little man and dove.jpg',
    widthInCm: 70,
    heightInCm: 85,
    details: {
      name: 'blauer Junge und Taube',
      materialAndPaint: 'Acrylics, pastel chalk on wood',
      date: 'April 2022',
    },
  },
  {
    imgFullName: 'Verstecken.jpeg',
    widthInCm: 50,
    heightInCm: 60,
    details: {
      name: 'Verstecken',
      materialAndPaint: 'Acrylics, charcoal on canvas',
      date: 'June 2022',
    },
  },
  {
    imgFullName: 'Jesus.jpeg',
    widthInCm: 70,
    heightInCm: 60,
    details: {
      name: 'Jesus?',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'April 2022',
    },
  },
  {
    imgFullName: 'Baloon-Rebirth.jpg',
    widthInCm: 50,
    heightInCm: 60,
    details: {
      name: 'transparente Ballone',
      materialAndPaint: 'Acrylics, pastel chalk on cardboard',
      date: 'June 2022',
    },
  },
  
  
]
