import { ArtPiece } from './earlyWorks'

export const Drawings: Array<ArtPiece> = [
  
  {
    imgFullName: 'Kea.jpg',
    widthInCm: 15,
    heightInCm: 20,
    details: {
      name: 'Untitled',
      materialAndPaint: 'Pencil',
      date: 'November 2020',
    },
  },
  {
    imgFullName: 'HN_smoking.jpg',
    widthInCm: 29.7,
    heightInCm: 42,
    details: {
      name: 'Untitled',
      materialAndPaint: 'Pencil',
      date: 'January 2021',
    },
  },



  
]
