import { ArtPiece } from 'constants/vinceZone/galleries/earlyWorks'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
import styled from 'styled-components'
import { FullScreenImg } from './FullScreenImg'
import { ImgDescription } from './ImgDescription'

export const FullScreenView = (props: {
  pieces: Array<ArtPiece>
  fullScreenPiece: number
  setFullScreenPiece: (v: number) => void
  close: () => void
}) => {
  const current = props.pieces[props.fullScreenPiece]
  return (
    <FullScreen>
      <MdClose
        size={32}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: 24,
          right: 24,
        }}
        onClick={() => props.close()}
      />
      <FullScreenImg key={current.imgFullName} imgName={current.imgFullName} />
      <ImgDescription piece={current} />
      <div style={{ display: 'flex', margin: 40, gap: 24 }}>
        <BsChevronLeft
          style={{
            cursor: 'pointer',
            opacity: props.fullScreenPiece > 0 ? 1 : 0.1,
          }}
          size={32}
          onClick={() =>
            props.fullScreenPiece > 0 &&
            props.setFullScreenPiece(props.fullScreenPiece - 1)
          }
        />
        <BsChevronRight
          style={{
            cursor: 'pointer',
            opacity: props.fullScreenPiece < props.pieces.length - 1 ? 1 : 0.1,
          }}
          size={32}
          onClick={() =>
            props.fullScreenPiece < props.pieces.length - 1 &&
            props.setFullScreenPiece(props.fullScreenPiece + 1)
          }
        />
      </div>
    </FullScreen>
  )
}

const FullScreen = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: calc(100vh - 120px);
  background-color: white;
  z-index: 100;

  animation-name: appearSlow;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  @keyframes appearSlow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
