import {
  DESKTOP_STYLE,
  MOBILE_BREAKPOINT_N,
  MOBILE_STYLE,
} from 'constants/variables'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import styled from 'styled-components'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useNavigate } from 'react-router'

export const useIsMobile = (): boolean => {
  const dataView = useContext(IsMobile)
  return dataView
}

const IsMobile = createContext<boolean>(false)

IsMobile.displayName = 'IsMobile'

const menuItems = [
  { label: 'Gallery', url: '/', key: 'gallery' },
  { label: 'About', url: '/about', key: 'about' },
  { label: 'News', url: '/news', key: 'news' },
  { label: 'Contact', url: '/contact', key: 'contact' },
]

export const Scaffold = (props: { children: ReactNode }) => {
  const [isMobile, setIsMobile] = useState<boolean>()
  const [showMobileNav, setShowMobileNav] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT_N)
    }

    setIsMobile(window.innerWidth < MOBILE_BREAKPOINT_N)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <IsMobile.Provider value={!!isMobile}>
      <Wrapper>
        <Header>
          <h1
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/')
              window.location.reload()
            }}
          >
            Vincent Weber
          </h1>
          {!isMobile ? (
            <MenuWrapper>
              {menuItems.map((item, idx) => (
                <MenuItem key={idx} onClick={() => navigate(item.url)}>
                  <p style={{ fontSize: 14 }}>{item.label.toUpperCase()}</p>
                </MenuItem>
              ))}
            </MenuWrapper>
          ) : (
            <>
              <GiHamburgerMenu
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: 24,
                  top: 48,
                }}
                size={24}
                onClick={() => setShowMobileNav(!showMobileNav)}
              />
              {showMobileNav && (
                <MobileNav>
                  {menuItems.map((item, idx) => (
                    <MenuItem
                      key={idx}
                      onClick={() => {
                        setShowMobileNav(false)
                        navigate(item.url)
                      }}
                    >
                      <p style={{ fontSize: 14 }}>{item.label.toUpperCase()}</p>
                    </MenuItem>
                  ))}
                </MobileNav>
              )}
            </>
          )}
        </Header>
        <Content>{props.children}</Content>
      </Wrapper>
    </IsMobile.Provider>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
`
const Header = styled.div`
  display: flex;
  flex: 0 0 120px;
  width: 100%;
  align-items: center;

  ${DESKTOP_STYLE} {
    padding: 32px 48px 32px 100px;
    justify-content: space-between;
  }

  ${MOBILE_STYLE} {
    padding: 0px 24px;
    justify-content: space-between;
  }
`

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px;
`

const MenuItem = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  padding: 0px 24px;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

const MobileNav = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  right: 24px;
  background-color: white;
  border: 1px solid black;
  padding: 8px 0px;
  z-index: 999;
`
