import { MOBILE_STYLE } from 'constants/variables'
import { ArtPiece } from 'constants/vinceZone/galleries/earlyWorks'
import styled from 'styled-components'

export const ImgDescription = (props: { piece: ArtPiece }) => {
  const piece = props.piece
  return (
    <Wrapper>
      <Text>{piece.details.name}</Text>
      <Text>{piece.details.materialAndPaint}</Text>
      <Text>
        {piece.widthInCm} cm x {piece.heightInCm} cm
      </Text>
      <Text>
        {piece.details.date}
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${MOBILE_STYLE} {
    margin-bottom: 40px;
  }
`

const Text = styled.p`
  text-align: center;
`
