import { NewsCard } from 'components/NewsCard'
import { newsEntries } from 'constants/vinceZone/newsEntries'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import styled from 'styled-components'

export const News = () => {
  return (
    <Wrapper>
      {newsEntries.map((entry, idx) => (
        <NewsCard news={entry} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  align-items: center;

  ${DESKTOP_STYLE} {
    padding: 64px;
  }

  ${MOBILE_STYLE} {
    padding: 32px 24px;
  }
`
