import { ContentType } from './aboutText'

export const contactText = [
  { type: ContentType.Title, text: 'Contact' },
  {
    type: ContentType.Text,
    text: '+41 (0) 77 524 15 89',
    
  },
  {
    type: ContentType.AnchorTag,
    text: 'weberv94@gmail.com',
    mailTo: 'weberv94@gmail.com',
  },
  {
    type: ContentType.AnchorTag,
    text: 'instagram.com/vincentwebersza',
    openLink: 'https://www.instagram.com/vincentwebersza/',
  },
]
