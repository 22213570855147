import { ArtPiece } from './earlyWorks'

export const Spring_2023: Array<ArtPiece> = [
 
  {
    imgFullName: 'waiting to unify.jpeg',
    widthInCm: 250,
    heightInCm: 60,
    details: {
      name: 'wartendes Pärchen',
      materialAndPaint: 'Acrylics, charcoal, oil, pastel chalk on wood',
      date: 'January 2023',
    },
  },
  {
    imgFullName: 'Man with white dove.jpeg',
    widthInCm: 40,
    heightInCm: 60,
    details: {
      name: 'Mann mit Taube',
      materialAndPaint: 'Oil, pastel chalk on wood',
      date: 'January 2023',
    },
  },
  {
    imgFullName: 'Kopf und Perle.jpg',
    widthInCm: 0,
    heightInCm: 0,
    details: {
      name: 'Kopf und perle',
      materialAndPaint: 'Acrylics, pastel chalk on canvas',
      date: 'February 2023',
    },
  },
  {
    imgFullName: 'erleuchtet.jpg',
    widthInCm: 48,
    heightInCm: 54,
    details: {
      name: 'Beleuchtet',
      materialAndPaint: 'Oil, pastel chalk, carving, burning on wood',
      date: 'Mai 2023',
    },
  },
  {
    imgFullName: 'Klarsicht.jpg',
    widthInCm: 0,
    heightInCm: 0,
    details: {
      name: 'Klarsicht',
      materialAndPaint: 'Acrylics and charcoal on wood',
      date: 'April 2023',
    },
  },
  {
    imgFullName: 'Mädchen_mit_Vogel.jpg',
    widthInCm: 0,
    heightInCm: 0,
    details: {
      name: 'Vogel an der Leine',
      materialAndPaint: 'Acrylics, pastel chalk on wood',
      date: 'March 2023',
    },
  },
  {
    imgFullName: 'gebeugte_Frau.jpg',
    widthInCm: 46,
    heightInCm: 53,
    details: {
      name: 'Bienenhaus',
      materialAndPaint: 'Oil, pastel chalk, char coal, nails, chord on wood',
      date: 'Mai 2023',
    },
  },
  {
    imgFullName: 'roter Ballon.jpg',
    widthInCm: 60,
    heightInCm: 120,
    details: {
      name: 'roter Ballon',
      materialAndPaint: 'Acrylics, charcoal, pastel chalk on wood',
      date: 'March 2023',
    },
  },
  {
    imgFullName: 'Kopf und Sanduhr.jpg',
    widthInCm: 0,
    heightInCm: 0,
    details: {
      name: 'untitled',
      materialAndPaint: 'Oil and pastel chalk on carved and burned wood',
      date: 'January 2023',
    },
  },
  {
    imgFullName: 'Ente.jpg',
    widthInCm: 68,
    heightInCm: 68,
    details: {
      name: 'Beleuchtet',
      materialAndPaint: 'Acrylics, oil, pastel chalk, carving, nails, chord on wood',
      date: 'Mai 2023',
    },
  },
  {
    imgFullName: 'Gunman-head-exploding.jpg',
    widthInCm: 150,
    heightInCm: 150,
    details: {
      name: 'weisser Ballon',
      materialAndPaint: 'Acrylics, charcoal, oil on wood',
      date: 'January 2023',
    },
  },
  
  
]
