import { ArtPiece } from './earlyWorks'

export const Digitalized: Array<ArtPiece> = [
  {
    imgFullName: 'Baum_n.jpg',
    widthInCm: 40,
    heightInCm: 80,
    details: {
      name: 'Baum mit Wurzeln digital',
      materialAndPaint: '',
      date: 'April 2023',
    },
  },
  {
    imgFullName: 'Blume_n.jpg',
    widthInCm: 50,
    heightInCm: 100,
    details: {
      name: 'Blume auf Haufen digital',
      materialAndPaint: '',
      date: 'April 2023',
    },
  },

  {
    imgFullName: 'Gieskanne_n.jpg',
    widthInCm: 70,
    heightInCm: 70,
    details: {
      name: 'Gieskanne digital',
      materialAndPaint: '',
      date: 'April 2023',
    },
  },
  {
    imgFullName: 'Sprungturm_n.jpg',
    widthInCm: 30,
    heightInCm: 110,
    details: {
      name: 'Sprungturm digital',
      materialAndPaint: '',
      date: 'April 2023',
    },
  }
  
]
