import { ArtPiece } from './earlyWorks'

export const Autumn_2023: Array<ArtPiece> = [

  {
    imgFullName: 'Am Ursprung-min.jpg',
    widthInCm: 45,
    heightInCm: 75,
    details: {
      name: 'Am Ursprung',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'December 2023',
    },
  },
  {
    imgFullName: 'Beleuchteet-min.jpg',
    widthInCm: 61,
    heightInCm: 76,
    details: {
      name: 'Beleuchtet',
      materialAndPaint: 'Oil on wood',
      date: 'December 2023',
    },
  },
  {
    imgFullName: 'Am roten Meer-min.jpg',
    widthInCm: 60,
    heightInCm: 54,
    details: {
      name: 'Am roten Meer',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'November 2023',
    },
  },
  {
    imgFullName: 'Herrin-min.jpg',
    widthInCm: 60,
    heightInCm: 64,
    details: {
      name: 'Herrin',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'January 2024',
    },
  },
 
  {
    imgFullName: 'puer-aeternus_min.jpg',
    widthInCm: 63,
    heightInCm: 104,
    details: {
      name: 'Puer Aeternus',
      materialAndPaint: 'Oil and pastel chalk on wood',
      date: 'September 2023',
    },
  },
  {
    imgFullName: 'danielzeichnetdiezeit_min.jpg',
    widthInCm: 90,
    heightInCm: 90,
    details: {
      name: 'Daniel zeichnet die Zeit',
      materialAndPaint: 'Oil, pastel chalk, chord and nails on wood',
      date: 'September 2023',
    },
  },

  
  {
    imgFullName: 'zarathustra_min.jpg',
    widthInCm: 60,
    heightInCm: 200,
    details: {
      name: 'Zarathustras Untergang',
      materialAndPaint: 'Oil, chords and nails on wood',
      date: 'September 2023',
    },
  },
  
  
  
  
]
